import React from "react";
import Navbar from "../Navbar";
import Call from "../Call";
import Email from "../Email";
import JoinSlider from "../JoinSlider.js/JoinSlider";
import profile1 from "../Images/profile1.jpeg";
import secondPerson from "../Images/secondPerson.jpeg";
import profile3 from "../Images/profile3.jpeg";
import dummy from "../Images/dummy.jpg";
import Footer from "../Footer";
import BelowFooter from "../BelowFooter";
import "../Pages/litigation.css";

const Litigation = () => {
  return (
    <div>
      <Navbar />
      <Call />
      <Email />
      <br />
      <br />
      <div className="join-container">
        <div className="join-sidebar">
          <div className="practice-area">
            <h2>PRACTICE AREA</h2>
            <hr />
            <ul>
              <li>
                <a href="/banking">Banking & Finance</a>
              </li>
              <li>
                <a href="/corporate">Corporate & Commercial</a>
              </li>
              <li>
                <a href="/litigation">Litigation & Arbitration</a>
              </li>
              <li>
                <a href="/mergers">Mergers & Acquisitions</a>
              </li>
              <li>
                <a href="/property">Intellectual Property</a>
              </li>
            </ul>
          </div>
          <div className="join-slider">
            <h2>WHAT OTHERS SAY</h2>
            <hr />
            <JoinSlider />
          </div>
          <div className="founder">
            <h2>KEY CONTACTS </h2>
            <hr />
            <div>
              <img
                alt="/"
                src={profile1}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Roshan Karki</a>
              <span>
                <ins>(Chairman)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={secondPerson}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Sulav Basnet</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={profile3}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Saujan Karki</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Prasad Malla</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Laxman Budathoki</a>
              <span>
                <ins>(Junior associate)</ins>
              </span>
            </div>
          </div>
        </div>
        <div className="join-content">
          <h1>Litigation & Arbitration</h1>
          <p>
            Our Litigation Lawyers have experience in handling all types of
            legal disputes including disputes before the Supreme Court of Nepal,
            various High Courts, lower courts and tribunals in Nepal. The Firm
            provides a smorgasbord of litigation services to a widely varying
            set of clients across different industries and business sectors from
            every corner of the world.
            <br />
            We represent clients in all manner of litigation in the courts of
            first instance and the appellate courts (courts of second instance)
            in cities across the country, and in the Supreme Court of Nepal
            which is the last court of appeal. Our practice covers:
            <ul>
              <li>Anti-Money Laundering</li>
              <li>Asset Finance</li>
              <li>Banking Regulation</li>
              <li>Cross-Border Financing</li>
              <li>Dispute Resolution</li>
              <li>Financial Technology</li>
              <li>Foreign Exchange</li>
              <li>International Project Finance</li>
              <li>International Financial Transactions</li>
              <li>Syndicated Loans</li>
              <li>Security and Enforcement</li>
              <li>Intercreditor Issues</li>
            </ul>
            The Firm’s primary focus is to responsibly and determinedly assist
            courts and tribunals in developing litigation in the most resource
            efficient and cost effective manner.  Unique remedies are explored
            and case-appropriate strategies applied to achieve the best results
            for our clients. With the representation of our arbitration and
            litigation lawyers, many clients have been successful in resolution
            of their legal disputes concerning company, employment, tax,
            constitutional, consumer protection and intellectual property
            matters and actions under penal laws, disputes in the sectors of
            information technology, infrastructure, banking and finance,
            bankruptcy, winding up and disputes arising out of contractual and
            commercial understandings.
            {/* <br />
            Our practice caters to a range of industries such as aviation,
            financial services, hospitality, manufacturing, infrastructure, and
            technology. */}
          </p>
          {/* <h4>Resources:</h4>
          <div className="resources_link_b">
            <ul>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }}
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <Footer />
      <BelowFooter />
    </div>
  );
};
export default Litigation;
