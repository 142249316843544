import React from "react";
import Navbar from "../Navbar";
import Call from "../Call";
import Email from "../Email";
import JoinSlider from "../JoinSlider.js/JoinSlider";
import profile1 from "../Images/profile1.jpeg";
import secondPerson from "../Images/secondPerson.jpeg";
import profile3 from "../Images/profile3.jpeg";
import dummy from "../Images/dummy.jpg";
import Footer from "../Footer";
import BelowFooter from "../BelowFooter";
import "../Pages/mergers.css";

const Mergers = () => {
  return (
    <div>
      <Navbar />
      <Call />
      <Email />
      <br />
      <br />
      <div className="join-container">
        <div className="join-sidebar">
          <div className="practice-area">
            <h2>PRACTICE AREA</h2>
            <hr />
            <ul>
              <li>
                <a href="/banking">Banking & Finance</a>
              </li>
              <li>
                <a href="/corporate">Corporate & Commercial</a>
              </li>
              <li>
                <a href="/litigation">Litigation & Arbitration</a>
              </li>
              <li>
                <a href="/mergers">Mergers & Acquisitions</a>
              </li>
              <li>
                <a href="/property">Intellectual Property</a>
              </li>
            </ul>
          </div>
          <div className="join-slider">
            <h2>WHAT OTHERS SAY</h2>
            <hr />
            <JoinSlider />
          </div>
          <div className="founder">
            <h2>KEY CONTACTS </h2>
            <hr />
            <div>
              <img
                alt="/"
                src={profile1}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Roshan Karki</a>
              <span>
                <ins>(Chairman)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={secondPerson}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Sulav Basnet</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={profile3}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Saujan Karki</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Prasad Malla</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Laxman Budathoki</a>
              <span>
                <ins>(Junior associate)</ins>
              </span>
            </div>
          </div>
        </div>
        <div className="join-content">
          <h1>Mergers & Acquisitions</h1>
          <p>
            Our team has the knowledge and expertise in dealing with a range of
            challenges that are posed in a M&A transaction. We have successfully
            handled a variety of M&A transactions. While structuring M&A deals,
            the team delivers tailor made, deal oriented practical legal advice
            that are efficient and viable.
            <br />
            We understand that clients require seamless and rapid solutions on
            complex transactions – delivered by a trusted legal partner who can
            work quickly, accurately and with a deep understanding of your
            business. Our practice covers:
            <ul>
              <li>Anti-Money Laundering</li>
              <li>Asset Finance</li>
              <li>Banking Regulation</li>
              <li>Cross-Border Financing</li>
              <li>Dispute Resolution</li>
              <li>Financial Technology</li>
              <li>Foreign Exchange</li>
              <li>International Project Finance</li>
              <li>International Financial Transactions</li>
              <li>Syndicated Loans</li>
              <li>Security and Enforcement</li>
              <li>Intercreditor Issues</li>
            </ul>
            Our team of seasoned M&A lawyers will work closely with you and your
            other advisers to develop an integrated transactional strategy that
            takes into consideration the factors that make your organisation
            unique. These include your governance framework, key business
            drivers, the market conditions in which you operate and your
            appetite for risk. This ‘big picture’ thinking enables us to
            consistently deliver commercially pragmatic solutions that will help
            you realise your strategic goals, take advantage of business
            opportunities and create value for you and your stakeholders.
            <br />
            No matter the size or complexity of the transaction at hand, we
            assist our clients to create better value from M&A activity – faster
            and with greater certainty.
          </p>
          {/* <h4>Resources:</h4>
          <div className="resources_link_b">
            <ul>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }}
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <Footer />
      <BelowFooter />
    </div>
  );
};
export default Mergers;
