import React from "react";

function DynamicCover() {
  return (
    <div className="cover">
      <div className="container">
        <h1>Welcome to Our Law Firm</h1>
        <p>
          We provide high-quality legal services to our clients. Our team of
          experienced lawyers is dedicated to helping you with your legal needs.
        </p>
      </div>
    </div>
  );
}

export default DynamicCover;
