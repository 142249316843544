import React from "react";
import Navbar from "../Navbar";
import Call from "../Call";
import Email from "../Email";
import JoinSlider from "../JoinSlider.js/JoinSlider";
import profile1 from "../Images/profile1.jpeg";
import secondPerson from "../Images/secondPerson.jpeg";
import profile3 from "../Images/profile3.jpeg";
import dummy from "../Images/dummy.jpg";
import Footer from "../Footer";
import BelowFooter from "../BelowFooter";
import "../Pages/banking.css";

const Banking = () => {
  return (
    <div>
      <Navbar />
      <Call />
      <Email />
      <br />
      <br />
      <div className="join-container">
        <div className="join-sidebar">
          <div className="practice-area">
            <h2>PRACTICE AREA</h2>
            <hr />
            <ul>
              <li>
                <a href="/banking">Banking & Finance</a>
              </li>
              <li>
                <a href="/corporate">Corporate & Commercial</a>
              </li>
              <li>
                <a href="/litigation">Litigation & Arbitration</a>
              </li>
              <li>
                <a href="/mergers">Mergers & Acquisitions</a>
              </li>
              <li>
                <a href="/property">Intellectual Property</a>
              </li>
            </ul>
          </div>
          <div className="join-slider">
            <h2>WHAT OTHERS SAY</h2>
            <hr />
            <JoinSlider />
          </div>
          <div className="founder">
            <h2>KEY CONTACTS </h2>
            <hr />
            <div>
              <img
                alt="/"
                src={profile1}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Roshan Karki</a>
              <span>
                <ins>(Chairman)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={secondPerson}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Sulav Basnet</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={profile3}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Saujan Karki</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Prasad Malla</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Laxman Budathoki</a>
              <span>
                <ins>(Junior associate)</ins>
              </span>
            </div>
          </div>
        </div>
        <div className="join-content">
          <h1>Banking & Finance</h1>
          <p>
            We have a historical banking and finance practice, and represent top
            national and international banks, companies and financial
            institutions. Our practice is regarded as a premier and
            sophisticated practice, and our client roster comprises the who’s
            who of the financial world. External commercial borrowings, trade
            finance, secured commercial lending, cross-border and national
            leasing and hire purchase, banking and securities regulatory work,
            project finance, structured finance, enforcement and realization of
            security interests and derivatives advisory work form the core of
            this practice. We have also effectively conducted many major bank
            fraud cases involving large sums of money. Whether you are buying,
            selling, starting or investing in a company, how the transaction is
            structured has enormous implications in both the short and the long
            term. Clever transactions minimise your risk and maximise your
            return; clear and informed guidance will always help you make the
            best decisions. Our practice covers:
            <ul>
              <li>Anti-Money Laundering</li>
              <li>Asset Finance</li>
              <li>Banking Regulation</li>
              <li>Cross-Border Financing</li>
              <li>Dispute Resolution</li>
              <li>Financial Technology</li>
              <li>Foreign Exchange</li>
              <li>International Project Finance</li>
              <li>International Financial Transactions</li>
              <li>Syndicated Loans</li>
              <li>Security and Enforcement</li>
              <li>Intercreditor Issues</li>
            </ul>
            Whatever the size or complexity of the transaction, Keypoint has the
            depth of experience and commerciality you need in a trusted legal
            adviser. We act for start-ups, high growth companies and large
            corporations as well as private investors, venture capitalists and
            business angels.  Our clients value the capability and insight we
            bring – built on many years of corporate law experience.
          </p>
          {/* <h4>Resources:</h4>
          <div className="resources_link_b">
            <ul>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }}
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <Footer />
      <BelowFooter />
    </div>
  );
};
export default Banking;
