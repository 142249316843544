import React from "react";
import "../Profile/profile.css";
import DynamicProfile from "./DynamicProfile";
import profile1 from "../Images/profile1.jpeg";
import secondPerson from "../Images/secondPerson.jpeg";
import profile3 from "../Images/profile3.jpeg";
import dummy from "../Images/dummy.jpg";

const Profile = () => {
  return (
    <div className="member">
      <div className="profile">
        <h1>Our Team</h1>
        <p>
          Karki Law Associates is a leading corporate law firm in Nepal. Our key
          practice areas include arbitration, banking, corporate, construction,
          foreign investment, joint ventures, mergers and acquisitions, project
          finance, property, technology, taxation and litigation. We work with
          banks and financial institutions, multinational companies,
          international organizations, Nepal’s leading corporates and as local
          counsel to international law firms. We have a proven track record of
          providing innovative solutions in high-value, complex and cross-border
          transactions and disputes.
        </p>
      </div>
      <div className="profile-list-container">
        <DynamicProfile
          img={profile1}
          name="Roshan Karki"
          detail="I am the founder, chairman and chief executive officer of Karki Law Associates"
          contact="9841818297"
          email="mailto:info.karkilaw@gmail.com"
          select="Contact Me"
        />

        <DynamicProfile
          img={secondPerson}
          name="Sulav Basnet"
          detail="I am the legal associate of Karki Law Associates"
          contact="01-4794184"
          email="mailto:info.karkilaw@gmail.com"
          select="Contact Me"
        />

        <DynamicProfile
          img={profile3}
          name="Saujan Karki"
          detail="I am the legal associate of Karki Law Associates"
          contact="01-4794184"
          email="mailto:info.karkilaw@gmail.com"
          select="Contact Me"
        />

        <DynamicProfile
          img={dummy}
          name="Prasad Malla"
          detail="I am the legal associate of Karki Law Associates"
          contact="01-4794184"
          email="mailto:info.karkilaw@gmail.com"
          select="Contact Me"
        />

        <DynamicProfile
          img={dummy}
          name="Laxman Budathoki"
          detail="I am the legal associate of Karki Law Associates"
          contact="01-4794184"
          email="mailto:info.karkilaw@gmail.com"
          select="Contact Me"
        />
      </div>
    </div>
  );
};
export default Profile;
