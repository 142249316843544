import React from "react";
import Navbar from "../Navbar";
import Call from "../Call";
import Email from "../Email";
import JoinSlider from "../JoinSlider.js/JoinSlider";
import profile1 from "../Images/profile1.jpeg";
import secondPerson from "../Images/secondPerson.jpeg";
import profile3 from "../Images/profile3.jpeg";
import dummy from "../Images/dummy.jpg";
import Footer from "../Footer";
import BelowFooter from "../BelowFooter";
import "../Pages/property.css";

const Property = () => {
  return (
    <div>
      <Navbar />
      <Call />
      <Email />
      <br />
      <br />
      <div className="join-container">
        <div className="join-sidebar">
          <div className="practice-area">
            <h2>PRACTICE AREA</h2>
            <hr />
            <ul>
              <li>
                <a href="/banking">Banking & Finance</a>
              </li>
              <li>
                <a href="/corporate">Corporate & Commercial</a>
              </li>
              <li>
                <a href="/litigation">Litigation & Arbitration</a>
              </li>
              <li>
                <a href="/mergers">Mergers & Acquisitions</a>
              </li>
              <li>
                <a href="/property">Intellectual Property</a>
              </li>
            </ul>
          </div>
          <div className="join-slider">
            <h2>WHAT OTHERS SAY</h2>
            <hr />
            <JoinSlider />
          </div>
          <div className="founder">
            <h2>KEY CONTACTS </h2>
            <hr />
            <div>
              <img
                alt="/"
                src={profile1}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Roshan Karki</a>
              <span>
                <ins>(Chairman)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={secondPerson}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Sulav Basnet</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={profile3}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Saujan Karki</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Prasad Malla</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Laxman Budathoki</a>
              <span>
                <ins>(Junior associate)</ins>
              </span>
            </div>
          </div>
        </div>
        <div className="join-content">
          <h1>Intellectual Property</h1>
          <p>
            Our Intellectual Property team provides strategic, commercial advice
            to ensure our clients maximise the value of their intellectual
            property. Our team of lawyers have a wealth of experience.
            Accordingly, we work with research organisations, multinational
            companies, startups, charities, schools, and government entities
            alike.
          </p>
          <h4 style={{ color: "#354c84" }}>Trademarks & Brands</h4>
          <p>
            Regardless of your industry, a well-protected and properly managed
            brand is one of your strongest assets. Whether it be a business or
            organisation. Our approach to branding strategy goes far beyond the
            mere registration of a mark. Rather, It extends into online commerce
            and the use of our clients’ brands on social media sites. While we
            work from the ground up, we begin by advising on business and online
            domain names. After that, we look at competitor activities and trade
            mark strategy. We manage global and local brand portfolios for
            clients. Particularly fields including government initiatives,
            cosmetics, agriculture, and biosciences. Our clients value our work
            in:
            <ul>
              <li>advising on brand development for new brands</li>
              <li>advising on strategy for existing brands</li>
              <li>early trade mark searching and validity assessments</li>
              <li>filing and registration services</li>
              <li>
                managing trade mark infringement (e.g. cease and desist) and
                brand encroachment (e.g. oppositions) matters
              </li>
              <li>portfolio management</li>
            </ul>
            We prosecute trade marks directly into Nepal.
          </p>
          <h4 style={{ color: "#354c84" }}>Patent</h4>
          <p>
            Our patents team excels in tailoring our services to the needs of
            our clients. We customise our approach and communications to
            clients’ requirements. At the same time, we ensure clients can make
            informed decisions on IP protection.. This helps us understand the
            client’s needs, while observing technology development first hand.
            Our patent services include:
            <ul>
              <li>patent drafting, filing and prosecution</li>
              <li>competitive National Phase Entry and renewals services</li>
              <li>patentability advice</li>
              <li>competitor search and patent watches</li>
              <li>licensing, assignment & recordals</li>
              <li>inventorship determination and disputes</li>
            </ul>
          </p>
          <h4 style={{ color: "#354c84" }}>Designs</h4>
          <p>
            Our design services include:
            <ul>
              <li>Design Application Preparation, Lodgement & Prosecution</li>
              <li>Design Registration & Infringement Advice</li>
              <li>Commercialisation & Market Strategies</li>
              <li>Design Registration Renewals</li>
            </ul>
          </p>
          <h4 style={{ color: "#354c84" }}>Foregin Invesment</h4>
          <p>
            Karkilaw Associates actively advises foreign investors on
            investments into Nepal and setting up businesses in Nepal. We assist
            foreign investors on a wide range of services relating to the
            structuring, establishment and operation of public and private
            companies, limited liability partnerships, and branch,
            representative and project offices in Nepal. We also provide foreign
            investors comprehensive advice in connection with the regulatory
            framework governing their investments into Nepal. In connection with
            such investments, we assist our clients on a wide range of
            transactions, including the acquisition and leasing of real property
            in Nepal.
          </p>
          {/* <h4>Resources:</h4>
          <div className="resources_link_b">
            <ul>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }}
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <Footer />
      <BelowFooter />
    </div>
  );
};
export default Property;
