import React from "react";

function GMap() {
  return (
    <div className="map-container">
      <h1 style={{ textAlign: "center" }}>Welcome to Karki Law Associates</h1>
      <p style={{ textAlign: "center" }}>
        The office of Karkilaw associates is located in New Baneshwor, near the
        Everest Hotel, along Madan Bhandari Road, Kathmandu, Nepal.
      </p>
      <br />
      <div className="map-iframe">
        <iframe
          title="Google Map"
          width="100%"
          height="400"
          frameBorder="0"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7065.81290098859!2d85.3268604!3d27.6892861!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19193a85db25%3A0x785e2bcc8a2a6cf5!2sKarki%20Law%20Associates!5e0!3m2!1sen!2snp!4v1696944961254!5m2!1sen!2snp"
          allowFullScreen
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default GMap;
