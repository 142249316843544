import React from "react";
import Navbar from "../Navbar";
import Call from "../Call";
import Email from "../Email";
import JoinSlider from "../JoinSlider.js/JoinSlider";
import profile1 from "../Images/profile1.jpeg";
import secondPerson from "../Images/secondPerson.jpeg";
import profile3 from "../Images/profile3.jpeg";
import dummy from "../Images/dummy.jpg";
import Footer from "../Footer";
import BelowFooter from "../BelowFooter";
import "../Pages/corporate.css";

const Corporate = () => {
  return (
    <div>
      <Navbar />
      <Call />
      <Email />
      <br />
      <br />
      <div className="join-container">
        <div className="join-sidebar">
          <div className="practice-area">
            <h2>PRACTICE AREA</h2>
            <hr />
            <ul>
              <li>
                <a href="/banking">Banking & Finance</a>
              </li>
              <li>
                <a href="/corporate">Corporate & Commercial</a>
              </li>
              <li>
                <a href="/litigation">Litigation & Arbitration</a>
              </li>
              <li>
                <a href="/mergers">Mergers & Acquisitions</a>
              </li>
              <li>
                <a href="/property">Intellectual Property</a>
              </li>
            </ul>
          </div>
          <div className="join-slider">
            <h2>WHAT OTHERS SAY</h2>
            <hr />
            <JoinSlider />
          </div>
          <div className="founder">
            <h2>KEY CONTACTS </h2>
            <hr />
            <div>
              <img
                alt="/"
                src={profile1}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Roshan Karki</a>
              <span>
                <ins>(Chairman)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={secondPerson}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Sulav Basnet</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={profile3}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Saujan Karki</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Prasad Malla</a>
              <span>
                <ins>(legal associate)</ins>
              </span>
            </div>
            <div>
              <img
                alt="/"
                src={dummy}
                style={{ width: "100px", height: "100px" }}
              />
              <a href="/">Laxman Budathoki</a>
              <span>
                <ins>(Junior associate)</ins>
              </span>
            </div>
          </div>
        </div>
        <div className="join-content">
          <h1>Corporate & Commercial</h1>
          <p>
            The Firm’s primary focus is to responsibly and determinedly assist
            courts and tribunals in developing litigation in the most resource
            efficient and cost effective manner.  Unique remedies are explored
            and case-appropriate strategies applied to achieve the best results
            for our clients. With the representation of our arbitration and
            litigation lawyers, many clients have been successful in resolution
            of their legal disputes concerning company, employment, tax,
            constitutional, consumer protection and intellectual property
            matters and actions under penal laws, disputes in the sectors of
            information technology, infrastructure, banking and finance,
            bankruptcy, winding up and disputes arising out of contractual and
            commercial understandings.
            <br />
            We understand our clients business and offer tailored solutions. Our
            lawyers can quickly assess commercial and legal risks involved in
            transactions and can offer strategic advice. Our practice covers:
            <ul>
              <li>
                incorporation and establishment of companies and other joints
                ventures (including involving government-based members);
              </li>
              <li>
                drafting constitutions, shareholders agreements and corporate
                operational documentation;
              </li>
              <li>company secretarial services;</li>
              <li>providing general ongoing commercial advice; and</li>
              <li>acting as in-house legal counsel for many of our clients.</li>
            </ul>
            Moreover, we expertly handle a wide scope of corporate law matters.
            Including, commercial agreements, competition and consumer law.
            Further, corporate structures, governance, restructuring, due
            diligence, compliance and risk management.The firm regularly acts to
            lead negotiations which have taken a wrong turn or hit an
            standstill. We accordingly identify the key issues and prepare
            creative and practical solutions. Finally, we put deals back on
            track. Above all, a transaction abandoned is a waste of time, money,
            and often goodwill. And equally important, it is unnecessary with
            the right legal advice and guidance.
            <br />
            In addition, several of our practitioners have experience as
            directors and chairs of both listed and unlisted companies, advising
            and being involved in a range of market activities. Above all, as a
            firm, we harness this Corporate & Commercial insight and personal
            experience. This allows us to provide concise, practical advice and
            documents which our clients can implement immediately.
          </p>
          {/* <h4>Resources:</h4>
          <div className="resources_link_b">
            <ul>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }}
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.lexology.com/library/detail.aspx?g=3c7436f3-298b-48ea-80b5-2479abf37fc5"
                  target="_blank"
                  style={{ color: "#354c84", transition: "color 0.3s" }} // Inline CSS for the default state
                >
                  Project Finance in Nepal - 2019
                  <span>
                    <ins>(Getting The Deal Through)</ins>
                  </span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <Footer />
      <BelowFooter />
    </div>
  );
};
export default Corporate;
